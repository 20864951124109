<template>
  <v-container>
    <v-row>
      <v-col> </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-card flat :to="{ name: 'IndividualTraining' }">
          <v-img class="dark align-center" :src="IndividualTraining"> </v-img>
          <p>
            Do you need more 1-on-1 training? If so, we have coaches who
            specialize in improving these skills:
          </p>
          <ul>
            <li>Hitting</li>
            <li>Fielding</li>
            <li>Pitching</li>
            <li>Base Running</li>
            <li>Catching</li>
          </ul>

          <v-btn
            class="learn-more-button"
            outlined
            :to="{ name: 'IndividualTraining' }"
            >Learn More</v-btn
          >
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card flat :to="{ name: 'YouthPrograms' }">
          <v-img class="dark align-center" :src="FiveToolProspect"> </v-img>

          <p>The game of baseball is built on players having 5 tools:</p>
          <ul>
            <li>Hit for Contact</li>
            <li>Hit for Power</li>
            <li>Base Running</li>
            <li>Fielding</li>
            <li>Throwing</li>
          </ul>

          <v-btn
            class="learn-more-button"
            outlined
            :to="{ name: 'YouthPrograms' }"
            >Learn More</v-btn
          >
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card flat :to="{ name: 'HittingPrograms' }">
          <v-img class="dark align-center" :src="HittingGroups"> </v-img>

          <p>
            During each session we will primarly focus on improving 3 hitting
            traits:
          </p>
          <ul>
            <li>Bat Speed</li>
            <li>Barrel Control</li>
            <li>Pitch Recognition</li>
          </ul>

          <v-btn
            class="learn-more-button"
            outlined
            :to="{ name: 'HittingPrograms' }"
            >Learn More</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IndividualTraining from '@/assets/images/IndividualTraining.svg'
import FiveToolProspect from '@/assets/images/5ToolProspect.svg'
import HittingGroups from '@/assets/images/HittingGroups.svg'
export default {
  data() {
    return {
      IndividualTraining: IndividualTraining,
      FiveToolProspect: FiveToolProspect,
      HittingGroups: HittingGroups,
    }
  },
}
</script>

<style scoped>
a.link {
  text-decoration: none;
}

.learn-more-button {
  margin-top: 15px;
}
</style>
